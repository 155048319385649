import { Box, Typography } from "@mui/material";
import { UnauthenticatedAppContainer } from "../../components/unauthenticatedAppContainer";
import WrappedButton from "../../components/wrappedButton";
import WrappedSnackbar from "../../components/wrappedSnackbar";
import WrappedTextField from "../../components/wrappedTextField";
import { useSignUp } from "./useSignUp";

function SignUp() {
  const {
    firstNameControl,
    lastNameControl,
    passwordControl,
    emailControl,
    formIsValid,
    onClickCreate,
    isCreatingUser,
    successSnackbarIsOpen,
    onCloseSuccessSnackbar,
    onClickLogIn,
  } = useSignUp();

  return (
    <UnauthenticatedAppContainer>
      <Box
        sx={{
          flexGrow: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            flexDirection: "column",
            display: "flex",
            gap: 2,
            padding: 2,
          }}
        >
          <Box
            sx={{
              marginBottom: 2,
              gap: 2,
            }}
          >
            <Typography variant="h5">Sign Up</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <WrappedTextField
              value={firstNameControl.value}
              label="First Name"
              onChange={firstNameControl.onChange}
              error={firstNameControl.hasError}
              helperText={firstNameControl.shownErrorMessage}
              disabled
            />
            <WrappedTextField
              value={lastNameControl.value}
              label="Last Name"
              onChange={lastNameControl.onChange}
              error={lastNameControl.hasError}
              helperText={lastNameControl.shownErrorMessage}
              disabled
            />
          </Box>
          <WrappedTextField
            value={emailControl.value}
            label="Email"
            onChange={emailControl.onChange}
            error={emailControl.hasError}
            helperText={emailControl.shownErrorMessage}
            disabled
          />
          <WrappedTextField
            value={passwordControl.value}
            label="Password"
            type="password"
            onChange={passwordControl.onChange}
            error={passwordControl.hasError}
            helperText={passwordControl.shownErrorMessage}
            disabled
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <WrappedButton
              onClick={onClickCreate}
              disabled={!formIsValid || isCreatingUser}
              showSpinner={isCreatingUser}
              variant="text"
            >
              Submit
            </WrappedButton>
            <WrappedSnackbar
              open={successSnackbarIsOpen}
              onClose={onCloseSuccessSnackbar}
              message="Success! Confirm your email address and then log in."
              actionMetadata={{
                onClick: onClickLogIn,
                text: "Log In",
              }}
            />
          </Box>
        </Box>
      </Box>
    </UnauthenticatedAppContainer>
  );
}

export default SignUp;
