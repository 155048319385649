import {
  Box,
  Card,
  Chip,
  Collapse,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ScheduleTeeTimeBase, Status,
} from "../../../../types";
import DeleteTeeTimeDialog from "../../../admin/allScheduledTeeTimes/scheduledTeeTimesTable/scheduledTeeTimeActions/deleteTeeTimeDialog.tsx";
import { useUserTeeTime } from "./useUserTeeTime";
import StatusComponent from "../../../../components/status";
import { colorFromStatus } from "../../../../utils/colorFromStatus";
import WrappedButton from "../../../../components/wrappedButton";
import TeeTimeDetails from "../../teeTimeDetails";
import { DateTime } from "luxon";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface UserTeeTimeProps {
  teeTime: ScheduleTeeTimeBase;
}

function UserTeeTime({ teeTime }: UserTeeTimeProps) {
  const theme = useTheme();
  const {
    text,
    border,
    isShowingDeleteModal,
    onClickDeleteModal,
    onHideDeleteModal,
    onDeleteSuccess,
    expanded,
    toggleExpanded,
    chipText,
    cardTitle,
    onClickEdit,
    onClickEditBackup,
    onClickEditPrimary,
    showPrimaryOrBackupMenu,
    anchorEl,
    onClosePrimaryOrBackupMenu,
    fullCourseText,
    backupCourseName,
  } = useUserTeeTime(teeTime);
  return (
    <Card
      elevation={4}
      sx={{
        width: "100%",
        flexShrink: 0,
        borderLeft: border,
      }}
    >
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        padding: 1,
      }}>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}>
          <Box sx={{
            paddingLeft: 1,
          }}>
            <Typography><strong>{fullCourseText}</strong></Typography>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}>
              <Typography variant="caption">{cardTitle}</Typography>
            </Box>
          </Box>
          <Box>
            <Chip label={chipText} size="small" icon={<StatusComponent status={teeTime.status} useDefaultColor />} color={colorFromStatus(teeTime.status)} />
          </Box>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto">
        <Box sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          flexDirection: "column",
          padding: 2,
          paddingTop: 0,
        }}>
          <Typography>{text}</Typography>
          <TeeTimeDetails
            courseName={teeTime.courseName}
            backup={backupCourseName}
            players={teeTime.players}
            date={DateTime.fromISO(teeTime.rangeStartInclusive)}
            rangeStart={DateTime.fromISO(teeTime.rangeStartInclusive)}
            rangeEnd={DateTime.fromISO(teeTime.rangeEndInclusive)}
            holeOption={teeTime.holes}
            transitOption={teeTime.transitOption}
            paymentOption={teeTime.paymentOption}
            status={teeTime.status}
          />
          {teeTime.status === Status.PENDING && (
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
            }}>
              <WrappedButton variant="outlined" onClick={onClickEdit} startIcon={<EditIcon />}>
                Edit
              </WrappedButton>
              <Menu anchorEl={anchorEl} open={showPrimaryOrBackupMenu} onClose={onClosePrimaryOrBackupMenu}>
                <MenuItem onClick={onClickEditPrimary}>Edit Primary ({teeTime.courseName})</MenuItem>
                <MenuItem onClick={onClickEditBackup}>{!!backupCourseName ? `Edit Backup (${backupCourseName})` : "Add Backup"}</MenuItem>
              </Menu>
              <WrappedButton color="error" variant="outlined" onClick={onClickDeleteModal} startIcon={<DeleteIcon />}>
                Delete
              </WrappedButton>
            </Box>
          )}
        </Box>
      </Collapse>
      <Box sx={{
        flexBasis: "auto",
        flexGrow: 0,
        flexShrink: 0,
        backgroundColor: theme.palette.grey["100"],
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderTop: 1,
        borderColor: "grey.400",
        "&:hover": {
          cursor: "pointer",
        }
      }} onClick={toggleExpanded}>
        <Box sx={{
          display: "flex",
          gap: 1,
          flexDirection: "row",
          alignItems: "center",
          padding: "2px",
        }}>
          <Typography variant="caption">
            <strong>
              {expanded ? "Show Less" : "Show More"}
            </strong>
          </Typography>
        </Box>
      </Box>

      {isShowingDeleteModal && (
        <DeleteTeeTimeDialog
          onClose={onHideDeleteModal}
          teeTime={teeTime}
          onDeleteSuccess={onDeleteSuccess}
        />
      )}
    </Card>
  );
}

export default UserTeeTime;
