export interface FullCourseMetadata extends CreateCourseMetadata {
  PK: string;
  SK: string;
}

export type SimpleCourse = Omit<FullCourseMetadata, "steps" | "status">;

export interface UpsertCourseMetadata extends CreateCourseMetadata {
  PK?: string;
  SK?: string;
}

export interface ChronoMetadata {
  // If this option is not provided we leave every 'course' selected.
  // If it is provided, we click everything that's not included
  // here.
  courseDropdownOptions?: string[];
}

export interface CreateCourseMetadata {
  name: string;
  city: string;
  steps: Step[];
  status: CourseStatus;
  offsetByPlayers: OffsetByPlayers;
  state: State;
  timezone: Timezone;
  teeTimeSchedulingSoftware: TeeTimeSchedulingSoftware;
  teeTimeHoleOption: TeeTimeHoleOption;
  chronoMetadata?: ChronoMetadata;
  courseID: string;
}

export interface OffsetByPlayers {
  1: CourseSchedulingOffset;
  2: CourseSchedulingOffset;
  3: CourseSchedulingOffset;
  4: CourseSchedulingOffset;
}

export enum CourseStatus {
  Draft = "Draft",
  Published = "Published",
  ReadyToPublish = "ReadyToPublish",
  Archived = "Archived",
}

export enum CoursePaymentOption {
  Online = "Online",
  InPerson = "InPerson",
  Both = "Both",
}

export interface CourseSchedulingOffset {
  canSchedule: boolean;
  daysOffset: number;
  hoursOffset: number;
  minutesOffset: number;
  secondsOffset: number;
}

export enum TeeTimeSchedulingSoftware {
  ForeUp = "ForeUp",
  Chrono = "Chrono",
  TeeItUp = "TeeItUp",
}

export enum CourseTransitOption {
  Cart = "Cart",
  Walking = "Walking",
  Both = "Both",
}

export enum StepType {
  Route = "Route",
  FindElementByText = "FindElementByText",
  FindElementByTextNavigateToAncestorAnchorHref = "FindElementByTextNavigateToAncestorAnchorHref",
  ClickElementWithText = "ClickElementWithText",
  ConditionalClickElementWithText = "ConditionalClickElementWithText",
  ScheduleForeUpTeeTime = "ScheduleForeUpTeeTime",
  ScheduleChronoUpTeeTime = "ScheduleChronoUpTeeTime",
  ScheduleTeeItUpTeeTime = "ScheduleTeeItUpTeeTime",
}

export interface RouteStep {
  type: StepType.Route;
  metadata: {
    url: string;
  };
}

export interface FindElementByTextStep {
  type: StepType.FindElementByText;
  metadata: {
    element: "span" | "a" | "h1";
    text: string;
  };
}

export interface FindElementByTextNavigateToAncestorAnchorHrefStep {
  type: StepType.FindElementByTextNavigateToAncestorAnchorHref;
  metadata: {
    element: "span";
    text: string;
  };
}

export interface ClickElementWithTextStep {
  type: StepType.ClickElementWithText;
  metadata: {
    element: "button";
    text: string;
  };
}

export interface Condition {
  players: 1 | 2 | 3 | 4 | "ignore",
  holes: TeeTimeHoleOption | "ignore",
  element: "button",
  text: string,
}

export interface ConditionalClickElementWithTextStep {
  type: StepType.ConditionalClickElementWithText,
  metadata: {
    conditions: Condition[]
  }
}

export interface ScheduleForeUpTeeTimeStep {
  type: StepType.ScheduleForeUpTeeTime;
}

export interface ScheduleChronoTeeTimeStep {
  type: StepType.ScheduleChronoUpTeeTime;
}

export interface ScheduleTeeItUpTeeTimeStep {
  type: StepType.ScheduleTeeItUpTeeTime;
}

export type ActionStep =
  | RouteStep
  | FindElementByTextNavigateToAncestorAnchorHrefStep
  | ClickElementWithTextStep
  | ConditionalClickElementWithTextStep
  | ScheduleForeUpTeeTimeStep
  | ScheduleChronoTeeTimeStep
  | ScheduleTeeItUpTeeTimeStep;
export type PageValidationStep = FindElementByTextStep;
export type Step = ActionStep | PageValidationStep;

export enum TeeTimeHoleOption {
  Nine = "Nine",
  Eighteen = "Eighteen",
  Both = "Both",
}

export enum State {
  ALABAMA = "Alabama",
  ALASKA = "Alaska",
  ARIZONA = "Arizona",
  ARKANSAS = "Arkansas",
  CALIFORNIA = "California",
  COLORADO = "Colorado",
  CONNECTICUT = "Connecticut",
  DELAWARE = "Delaware",
  FLORIDA = "Florida",
  GEORGIA = "Georgia",
  GUAM = "Guam",
  HAWAII = "Hawaii",
  IDAHO = "Idaho",
  ILLINOIS = "Illinois",
  INDIANA = "Indiana",
  IOWA = "Iowa",
  KANSAS = "Kansas",
  KENTUCKY = "Kentucky",
  LOUISIANA = "Louisiana",
  MAINE = "Maine",
  MARYLAND = "Maryland",
  MASSACHUSETTS = "Massachusetts",
  MICHIGAN = "Michigan",
  MINNESOTA = "Minnesota",
  MISSISSIPPI = "Mississippi",
  MISSOURIA = "Missouri",
  MONTANA = "Montana",
  NEBRASKA = "Nebraska",
  NEVADA = "Nevada",
  NEW_HAMPSHIRE = "New Hampshire",
  NEW_JERSEY = "New Jersey",
  NEW_MEXICO = "New Mexico",
  NEW_YORK = "New York",
  NORTH_CAROLINA = "North Carolina",
  NORTH_DAKOTA = "North Dakota",
  OHIO = "Ohio",
  OKLAHOMA = "Oklahoma",
  OREGON = "Oregon",
  PENNSYLVANIA = "Pennsylvania",
  PUERTO_RICO = "Puerto Rico",
  RHODE_ISLAND = "Rhode Island",
  SOUTH_CAROLINA = "South Carolina",
  SOUTH_DAKOTA = "South Dakota",
  TENNESEE = "Tennessee",
  TEXAS = "Texas",
  UTAH = "Utah",
  VERMONT = "Vermont",
  VIRGINIA = "Virginia",
  WASHINGTON = "Washington",
  WEST_VIRGINIA = "West Virginia",
  WISCONSIN = "Wisconsin",
  WYOMING = "Wyoming",
}

export enum Timezone {
  PACIFIC = "America/Los_Angeles",
  MOUNTAIN = "America/Denver",
  ARIZONA = "America/Phoenix",
  CENTRAL = "America/Chicago",
  EASTERN = "America/New_York",
}

export enum Status {
  PENDING = "PENDING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export interface BackupTeeTime {
  coursePK: string;
  courseName: string;
  login: string;
  password: string;
  courseID?: string;
  teeTimeSchedulingSoftware: TeeTimeSchedulingSoftware;
}

export interface ScheduleTeeTimeBase
  extends CommonScheduleTeeTimeFields,
  TTL,
  DisplayCourseInformation {
  PK: string;
  SK: string;
  PK2: string;
  SK2: string;
  // ISO 8601
  rangeStartInclusive: string;
  // ISO 8601
  rangeEndInclusive: string;
  status: Status;
  timezone: Timezone;
  username: string;
  created: string;
  statusReason?: string;
  runNow?: boolean;
  // ISO 8601
  // This is effectively the same thing as SK2 but in a different format
  runTime?: string;
  // These next three are added to the tee time after the booking attempt.
  timeSlots?: TimeSlots;
  // The actual tee time that was scheduled.
  bookedTime?: string;
  // The booking error code (if any)
  bookingErrorCode?: BookingErrorCode;
}

export interface ScheduleTeeTimeEditRequest extends ScheduleTeeTimeCreateRequest {
  teeTimePKToDelete: string;
}

export interface ScheduleTeeTimeEditRequestV2 extends ScheduleTeeTimeCreateRequestV2 {
  teeTimePKToDelete: string;
}

export interface ScheduleTeeTimeCreateRequestV2 {
  day: number;
  month: number;
  year: number;
  rangeStartInclusive: ITimeRange;
  rangeEndInclusive: ITimeRange;
  players: 1 | 2 | 3 | 4;
  holes: TeeTimeHoleOption;
  transitOption: CourseTransitOption;
  paymentOption: CoursePaymentOption;
  courses: {
    PK: string,
    login: string,
    password: string,
  }[];
}

export interface ScheduleTeeTimeCreateRequest
  extends CommonScheduleTeeTimeFields {
  day: number;
  month: number;
  year: number;
  rangeStartInclusive: ITimeRange;
  rangeEndInclusive: ITimeRange;
  // This is used exclusively for full tests
  runWithDevelopmentScript?: boolean;
}

export interface CommonScheduleTeeTimeFields {
  players: 1 | 2 | 3 | 4;
  holes: TeeTimeHoleOption;
  transitOption: CourseTransitOption;
  paymentOption: CoursePaymentOption;
  coursePK: string;
  login?: string;
  password?: string;
  backupTeeTimes?: BackupTeeTime[];
}

export interface FullCourseTestRun
  extends TTL,
  BasicDynamoRecord,
  DisplayCourseInformation {
  created: string;
  teeTime: ScheduleTeeTimeBase;
  coursePK: string;
  status: Status;
  bookingAttemptData?: BookingAttemptData;
  isAutomaticallyGenerated?: boolean;
  runWithDevelopmentScript?: boolean;
}

export interface ITimeRange {
  hour: number;
  minute: number;
}

export interface TTL {
  ttl: number;
}

export interface BasicDynamoRecord {
  PK: string;
  SK: string;
}

export interface DeleteRecordRequest {
  PK: string;
  SK: string;
}

export interface DisplayCourseInformation {
  courseName: string;
  courseCity: string;
  courseState: State;
}

export interface ScheduleTeeTimes {
  teeTimesOrderedByCreationTime: ScheduleTeeTimeBase[];
  fullCourseMetadata: FullCourseMetadata;
}

export interface ScheduleTeeTimesV2 {
  teeTimesOrderedByCreationTime: {
    teeTime: ScheduleTeeTimeBase,
    timeSlots: TimeSlots,
  }[];
  fullCourseMetadata: FullCourseMetadata;
}

export interface SignUpRequest extends UserCommonFields {
  password: string;
}

export enum AdminRole {
  None = "None",
  Super = "Super",
}

export interface User extends UserCommonFields, BasicDynamoRecord {
  adminRole: AdminRole;
  stripeUserID: string;
}

export interface UserCommonFields {
  email: string;
  firstName: string;
  lastName: string;
}

export enum HttpErrorCode {
  BadRequest = 400,
  Unauthoried = 401,
  Forbidden = 403,
}

export interface TeeTimeWithOptions {
  teeTime: ScheduleTeeTimeBase;
  options: TimeAndOptionalCourseName[];
}

export interface TimeAndOptionalCourseName {
  // Up to three ISO strings for the tee times that
  // are best suited to this user.
  time: string;
  // The course name here is used for Chrono golf and helps us to select
  // the correct course option when booking a tee time for the user.
  courseName?: string;
}

export interface TeeTimeWithOptionsAndCourseData extends TeeTimeWithOptions {
  fullCourseMetadata: FullCourseMetadata;
}

export interface TimeSlot {
  // ISO string to indicate the start time for the user
  startTimeInclusive: string;
  // ISO string to indicate the end time for the user (exclusive)
  endTimeExclusive: string;
}

export type TimeSlots = TimeSlot[];

export interface BookingAttemptData {
  // An ISO string or possibly null
  time: string | null;
  // All the logs that help us understand what happened during the booking process
  logs: LogWithTime[];
  errorCode: BookingErrorCode;
}

export interface LogWithTime {
  text: string,
  // ISO string to help us speed up slower parts of tee time booking
  time: string,
}

export interface RunTeeTimeV2RecordBody {
  teeTime: ScheduleTeeTimeBase,
  fullCourseMetadata: FullCourseMetadata,
  timeSlots: TimeSlots,
}

export interface ScheduleTeeTimeLogs extends BasicDynamoRecord, TTL {
  logs: LogWithTime[];
}

export interface SavedUsernameAndPassword extends BasicDynamoRecord {
  login: string;
  password: string;
}

export enum BookingErrorCode {
  None = "None",
  RunThroughNonSchedulingStepsError = "RunThroughNonSchedulingStepsError",
  SelectDate = "SelectDate",
  ValidateOnTeeTimePage = "ValidateOnTeeTimePage",
  Login = "Login",
  SelectHoles = "SelectHoles",
  ClickHoleOption = "ClickHoleOption",
  SelectPlayers = "SelectPlayers",
  SelectContinueButton = "SelectContinueButton",
  SelectPayment = "SelectPayment",
  CompletePurchaseButton = "CompletePurchaseButton",
  ClickModalContinueButton = "ClickModalContinueButton",
  ClickModalBookTimeButton = "ClickModalBookTimeButton",
  ClickContinueToPaymentButton = "ClickContinueToPaymentButton",
  ClickFirstAvailableCreditCardOption = "ClickFirstAvailableCreditCardOption",
  ClickGoToPurchaseReview = "ClickGoToPurchaseReview",
  NoTeeTimesForUser = "NoTeeTimesForUser",
  ClickingTeeTimeOption = "ClickingTeeTimeOption",
  ClickLoginAndContinue = "ClickLoginAndContinue",
  FillOutLoginModal = "FillOutLoginModal",
  FillOutCartOption = "FillOutCartOption",
  AgreeToReservationTerms = "AgreeToReservationTerms",
  ClickReservationReviewSubmitButton = "ClickReservationReviewSubmitButton",
  UncaughtException = "UncaughtException",
  ChronoTeeTimeReservationPage = "ChronoTeeTimeReservationPage",
  ChronoHandleCourseOptions = "ChronoHandleCourseOptions",
  AttemptSelectTeeTime = "AttemptSelectTeeTime",
  ClickBookTimeButton = "ClickBookTimeButton",
}