import { Box, Typography } from "@mui/material";
import { UnauthenticatedAppContainer } from "../../components/unauthenticatedAppContainer";
import WrappedButton from "../../components/wrappedButton";
import WrappedSnackbar from "../../components/wrappedSnackbar";
import WrappedTextField from "../../components/wrappedTextField";
import { useLogin } from "./useLogin";

function Login() {
  const {
    passwordControl,
    emailControl,
    formIsValid,
    onClickLogIn,
    isSigningIn,
    onClickResetPassword,
    onSubmit,
    errorMessage,
    clearErrorMessage,
  } = useLogin();

  return (
    <UnauthenticatedAppContainer>
      <Box
        sx={{
          flexGrow: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{
          width: "100%",
          maxWidth: "600px",
          paddingLeft: 2,
          paddingRight: 2,
        }}>
          <form onSubmit={onSubmit}>
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  marginBottom: 2,
                  gap: 2,
                }}
              >
                <Typography variant="h5">Log In</Typography>
              </Box>
              <WrappedTextField
                value={emailControl.value}
                label="Email"
                onChange={emailControl.onChange}
                error={emailControl.hasError}
                helperText={emailControl.shownErrorMessage}
                disabled
              />
              <WrappedTextField
                value={passwordControl.value}
                label="Password"
                type="password"
                onChange={passwordControl.onChange}
                error={passwordControl.hasError}
                helperText={passwordControl.shownErrorMessage}
                disabled
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <WrappedButton onClick={onClickResetPassword} variant="text">
                  Reset Password
                </WrappedButton>
                <WrappedButton
                  onClick={onClickLogIn}
                  disabled={!formIsValid || isSigningIn}
                  showSpinner={isSigningIn}
                  variant="text"
                  type="submit"
                >
                  Login
                </WrappedButton>
                <WrappedSnackbar
                  open={!!errorMessage}
                  onClose={clearErrorMessage}
                  message={errorMessage}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </UnauthenticatedAppContainer>
  );
}

export default Login;
